import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './assets/css/bootstrap.css'
// import './assets/css/grails.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css';
import './assets/css/main.css'

Vue.config.productionTip = false
Vue.use(Vuetify);

new Vue({
  el: '#app',
  router,
  components: { App },
  Vuetify,
  template: '<App/>'
})
