<template>
  <v-container>
    <slot>
      <v-form ref="fromLogin" lazy-validation>
        <v-row justify="center">
          <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Business Intelligence - Patho-BI</span>
          <div class="light-blue-rectangle mt-3 ml-5"></div>
        </v-row>
        <v-row justify="center" class="pt-7">
          <v-col cols="12" sm="8" md="4">
            <v-card class="text-center" elevation="2">
              <v-card-title>
                <h1 class="display-1">Entrar</h1>
              </v-card-title>
              <v-card-text>
                <v-text-field type="email" v-model="username" label="Login" @keydown.enter="login"></v-text-field>
                <v-text-field type="password" v-model="password" label="Senha" @keydown.enter="login"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="login">
                  Entrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </slot>
  </v-container>

</template>

<script>
import Swal from "sweetalert2";
import api from "@/plugins/api";
export default {
  name: "login",

  data(){
    return {
      username: '',
      password: '',
    }
  },

  methods: {
    async login(){
      if (!this.$refs.fromLogin.validate()) {
        return
      }
      try {
        const res = await api.post('/api/login', {username: this.username, password: this.password});
        let dados = res.data;
        localStorage.setItem('token', dados.access_token);
        this.$router.push('/home');
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    }
  }
}

</script>


<style scoped>
.light-blue-rectangle {
  width: 110vh;
  height: 2vh;
  background-color: #2196f3;
}

</style>