<template>
  <v-container>
    <v-row class="mb-10">
      <v-row justify="space-around" class=" pl-16 pt-5">
        <v-col cols="12">
          <v-row>
            <MenuSuporte class="mt-6"></MenuSuporte>
            <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Configurações - Laboratório</span>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="5">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Criar laboratório</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="nomeLaboratorio" label="Nome do Laboratório"/>
            </v-card-text>
            <v-card-text>
              <v-select v-model="empresaSelecionada" :items="empresas" item-text="nome" label="Empresa"></v-select>
            </v-card-text>
            <v-card-text>
              <v-text-field v-model="cnpj" label="CNPJ (Apenas números)"/>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bg-primary" block @click="saveLaboratorio">Criar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
      </v-col>
      <v-col cols="7">
        <slot>
          <v-card color="#121212" class="text-center" elevation="8">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item-title class="mt-2">Laboratórios cadastrados</v-list-item-title>
                <v-divider class="mt-4"></v-divider>
                  <v-row class="justify-space-around">
                    <v-col cols="4">
                      <v-text-field persistent-hint hint="Filtrar por laboratório" class="mt-6" style="width: 50vh;" v-model="filtroLaboratorio" label="Nome do Laboratório" filled rounded dense append-icon="mdi mdi-magnify"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select clearable clear-icon="mdi mdi-close-circle" filled rounded dense hint="Filtrar por Empresa" persistent-hint class="mt-6" v-model="filtroEmpresa" :items="empresas" item-text="nome" label="Empresa" append-icon="mdi mdi-magnify"></v-select>
                    </v-col>
                </v-row>
                <v-divider class="mt-1"></v-divider>
                <v-list-item @click="hubLaboratorio(item.id)" v-for="(item, i) in laboratorios" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.nome"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/plugins/api";
import Swal from "sweetalert2";
import MenuSuporte from "@/components/MenuSuporte.vue";

export default {
  name: 'Welcome',
  components: {MenuSuporte},

  data(){
    return {
      laboratorios: [],
      empresas: [],
      nomeLaboratorio: '',
      empresaSelecionada: '',
      filtroLaboratorio: '',
      filtroEmpresa: '',
      cnpj: '',
    }
  },

  watch: {
    'filtroLaboratorio': 'findLaboratorios',
    'filtroEmpresa': 'findLaboratorios',
  },

  mounted() {
    this.findLaboratorios();
    this.findEmpresas();
  },

  methods: {
    findLaboratorios(){
      api.post('api/configuracoes/laboratorioslist', {nome: this.filtroLaboratorio, empresa: this.filtroEmpresa}).then(response => {
        this.laboratorios = response.data;
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    findEmpresas(){
      api.post('api/configuracoes/empresaslist', {nome: ''}).then(response => {
        this.empresas = response.data;
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    hubLaboratorio(id) {
      if(id) {
        this.$router.push({ path: `/laboratorioHub/${id}` });
      }
    },

    async saveLaboratorio(){
      try {
        if(this.nomeLaboratorio.length < 2){
          return await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Informe o nome da empresa.',
          });
        }
        await api.post('/api/configuracoes/saveLaboratorio', {nome: this.nomeLaboratorio, empresaId: this.empresaSelecionada, cnpj: this.cnpj});
        location.reload();
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    }
  }
};

</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-style: italic; /* Itálico */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.light-blue-rectangle {
  width: 100vh;
  height: 2vh;
  background-color: #2196f3;
}

</style>