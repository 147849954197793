import axios from "axios";
import Vue from "vue";


const api = axios.create({baseURL: process.env.VUE_APP_SERVER_URL});

let load
let loadState
api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    if (config.noSpinner !== true && loadState=== false && localStorage.getItem("exibirSpinner") === 'true') {
        load = Vue.$loading.show({container: null,canCancel: false});
        loadState = true
    }
    return config;
}, function (error) {
    if (loadState === true){
        load.hide()
        loadState = false
    }
    return Promise.reject(error);
});

export default api;
