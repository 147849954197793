import Vue from 'vue'
import Router from 'vue-router'
import Welcome from '@/components/Welcome'
import Login from "@/components/Autenticacao/login.vue";
import Empresa from "@/components/Configuracoes/empresa.vue";
import Laboratorio from "@/components/Configuracoes/laboratorio.vue";
import Usuario from "@/components/Configuracoes/usuario.vue";
import EmpresaHub from "@/components/Configuracoes/empresaHub.vue";
import LaboratorioHub from "@/components/Configuracoes/laboratorioHub.vue";
import UsuarioHub from "@/components/Configuracoes/usuarioHub.vue";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      params: true,
    },
    {
      path: '/empresa',
      name: 'Empresa',
      component: Empresa,
      params: true,
    },
    {
      path: '/empresaHub/:id',
      name: 'HubEmpresa',
      component: EmpresaHub,
      params: true,
    },
    {
      path: '/laboratorio',
      name: 'Laboratorio',
      component: Laboratorio,
      params: true,
    },
    {
      path: '/laboratorioHub/:id',
      name: 'HubLaboratorio',
      component: LaboratorioHub,
      params: true,
    },
    {
      path: '/usuario',
      name: 'Usuario',
      component: Usuario,
      params: true,
    },
    {
      path: '/usuarioHub/:id',
      name: 'HubUsuario',
      component: UsuarioHub,
      params: true,
    },
    {
      path: '/home',
      name: 'Welcome',
      component: Welcome,
      params: true,
    },
    {
      path: '/conclusao',
      name: 'Conclusao',
      component: () => import('@/components/Conclusão/conclusao.vue'),
      params: true,
    },
    {
      path: '/numeroExamesLiberados',
      name: 'NumeroExamesLiberados',
      component: () => import('@/components/Evolução do Número de Exames Liberados/numeroDeExamesLiberados.vue'),
      params: true,
    },
    {
      path: '/tempoDeExamesPorSetor',
      name: 'TempoDeExamesPorSetor',
      component: () => import('@/components/Tempo de Exames por Setor/tempoDeExamesPorSetor.vue'),
      params: true,
    },
    {
      path: '/examesEncerrados',
      name: 'ExamesEncerrados',
      component: () => import('@/components/Exames Encerrados/examesEncerrados.vue'),
      params: true,
    },
    {
      path: '/tempoDeEntrega',
      name: 'TempoDeEntrega',
      component: () => import('@/components/Tempo de Entrega/tempoDeEntrega.vue'),
      params: true,
    },
    {
      path: '/examesEncerradosClassificador',
      name: 'ExamesEncerradosClassificador',
      component: () => import('@/components/Exames Encerrados Classificador/examesEncerradosClassificador.vue'),
      params: true,
    },
    {
      path: '/evolucaoDoNumeroDeExamesPorPatologista',
      name: 'EvolucaoDoNumeroDeExamesPorPatologista',
      component: () => import('@/components/Evolução do nº de exames por patologista/evolucaoDoNumeroDeExamesPorPatologista.vue'),
      params: true,
    },
    {
      path: '/examesAbertos',
      name: 'ExamesAbertos',
      component: () => import('@/components/Exames Abertos/examesAbertos.vue'),
      params: true,
    }
  ]
})
