<template>
  <v-container>
    <v-row class="mb-10">
      <v-row justify="space-around" class=" pl-16 pt-5">
        <v-col cols="12">
          <v-row>
            <MenuSuporte class="mt-6"></MenuSuporte>
            <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Configurações - {{ this.usuario.username }}</span>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="4">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Editar nome do usuário</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="nomeNovo" label="Novo username"/>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bg-primary" block @click="editNomeUsuario">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
        <slot>
          <v-card color="#121212" class="text-center centered justify-center mt-2" elevation="8" >
            <v-card-title>
              <h4 class="display-1">Desativar usuário</h4>
            </v-card-title>
            <v-card-actions>
              <v-btn class="error" block @click="desativarUsuario">Desativar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
      </v-col>
      <v-col cols="8">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Informações do Usuário</h3>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-card-title>
                  <h4 class="display-1">Username: {{ usuario.username }}</h4>
                </v-card-title>
                <v-card-title v-if="usuario.empresa">
                  <h4 class="display-1">Empresa: {{ empresaOuLabDoUsuario }}</h4>
                </v-card-title>
                <v-card-title v-if="usuario.laboratorio">
                  <h4 class="display-1">Laboratório: {{ empresaOuLabDoUsuario }}</h4>
                </v-card-title>
                <v-card-title>
                  <h4 class="display-1">Papel: {{ papel }}</h4>
                </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import api from "@/plugins/api";
import Swal from "sweetalert2";
import MenuSuporte from "@/components/MenuSuporte.vue";

export default {
  components: {MenuSuporte},
  data(){
    return {
      laboratorio: '',
      laboratorios: [],
      filtroLaboratorio: '',
      nomeNovo: '',
      papelSelecionado: '',
      empresaOuLaboratorio: '',
      usuario: '',
      papel: '',
      empresaOuLabDoUsuario: '',
    }
  },

  mounted() {
    const url = window.location.href
    this.pegarUsuarioAPartirDaURL(url)
    this.pegarPapel()
    this.findPapeis()
  },

  methods: {
    pegarUsuarioAPartirDaURL(url){
      const partes = url.split("/");
      const usuarioId = partes[partes.length - 1];

      api.post('api/configuracoes/usuarioPeloId', {usuarioId: usuarioId}).then(response => {
        this.usuario = response.data[0];
      });

      api.post('api/configuracoes/responsabilidadePorUsuario', {usuarioId: usuarioId}).then(response => {
        this.empresaOuLaboratorio = response.data[0];
        this.empresaOuLabDoUsuario = response.data[1];
      });
    },

    pegarPapel(){
      const url = window.location.href
      const partes = url.split("/");
      const usuarioId = partes[partes.length - 1];
      api.post('api/configuracoes/papelPorUsuario', {usuarioId: usuarioId}).then(response => {
        this.papel = response.data[0];
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    findPapeis(){
      api.post('api/configuracoes/papeisList').then(response => {
        this.papeis = response.data;
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    async editNomeUsuario(){
      const url = window.location.href
      const partes = url.split("/");
      const usuarioId = partes[partes.length - 1];
      try {
        if(this.nomeNovo.length < 2){
          return await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Informe o nome do usuário.',
          });
        }
        await api.post('/api/configuracoes/editNomeUsuario', {nome: this.nomeNovo, usuarioId: usuarioId});
        location.reload();
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    },

    async desativarUsuario(){
      try {
        const url = window.location.href
        const partes = url.split("/");
        const usuarioId = partes[partes.length - 1];
        await api.post('/api/configuracoes/desativarUsuario', {usuarioId: usuarioId});
        this.$router.push('/usuario');
        await Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Usuario desativado com sucesso.',
        });
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    },
  }


}

</script>

<style scoped>

</style>