<template>
  <v-container>
    <v-row class="mb-10">
      <v-row justify="space-around" class=" pl-16 pt-5">
        <v-col cols="12">
          <v-row>
            <MenuSuporte class="mt-6"></MenuSuporte>
            <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Configurações - Empresa</span>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="4">
        <slot>
            <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
              <v-card-title>
                <h3 class="display-2">Criar empresa</h3>
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="nomeEmpresa" label="Nome da Empresa"/>
              </v-card-text>
              <v-card-actions>
                <v-btn class="bg-primary" block @click="saveEmpresa">Criar</v-btn>
              </v-card-actions>
            </v-card>
        </slot>
      </v-col>
      <v-col cols="8">
        <slot>
          <v-card color="#121212" class="text-center" elevation="8">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item-title class="mt-2">Empresas Cadastradas</v-list-item-title>
                <v-divider class="mt-4"></v-divider>
                <v-row>
                  <v-col cols="3">
                  </v-col>
                  <v-col cols="8">
                    <v-text-field persistent-hint hint="Filtrar por empresa" class="mt-6" style="width: 50vh;" v-model="filtroEmpresa" label="Nome da Empresa" filled rounded dense append-icon="mdi mdi-magnify"></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-1"></v-divider>
                <v-list-item @click="hubEmpresa(item.id)" v-for="(item, i) in empresas" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.nome"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/plugins/api";
import Swal from "sweetalert2";
import MenuSuporte from "@/components/MenuSuporte.vue";

export default {
    name: 'Welcome',
  components: {MenuSuporte},

    data(){
      return {
        empresas: [],
        nomeEmpresa: '',
        filtroEmpresa: '',
      }
    },

    watch: {
      'filtroEmpresa': 'findEmpresas',
    },

    mounted() {
      this.findEmpresas();
    },

    methods: {
      findEmpresas(){
        api.post('api/configuracoes/empresaslist', {nome: this.filtroEmpresa}).then(response => {
          this.empresas = response.data;
        }).catch(error => {
          if (error.response && error.response.status === 401) {
            Swal.fire({
              title: 'Acesso não autorizado',
              text: 'Faça login para continuar.',
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: 'Fazer login',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/';
              }
            });
          }
        })
      },

      hubEmpresa(id) {
        if(id) {
          this.$router.push({ path: `/empresaHub/${id}` });
        }
      },

      async saveEmpresa(){
        try {
          if(this.nomeEmpresa.length < 2){
            return await Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: 'Informe o nome da empresa.',
            });
          }
          await api.post('/api/configuracoes/saveEmpresa', {nome: this.nomeEmpresa});
          location.reload();
        } catch (err) {
          if (err.response) {
            await Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: err.response.data,
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              timer: 3000,
              timerProgressBar: true,
              text: 'Falha na conexão com o servidor',
            });
          }
        }
      }
    }
};

</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-style: italic; /* Itálico */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.light-blue-rectangle {
  width: 90vh;
  height: 2vh;
  background-color: #2196f3;
}

</style>