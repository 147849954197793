<template>
  <div>
    <v-app-bar-nav-icon style="color: #2196f3;" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <v-spacer></v-spacer>

    <v-navigation-drawer
        v-model="drawer" absolute bottom temporary>
      <v-list nav dense >
        <v-list-item-group v-model="group" active-class="blue">

          <v-list-item class="mt-6 pa-1" to="/home">
            <v-icon>mdi mdi-home</v-icon>
            <v-list-item-title class="pl-2">Página inicial</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6 pa-1" to="/empresa">
            <v-icon>mdi mdi-domain</v-icon>
            <v-list-item-title class="pl-2">Empresa</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6 pa-1" to="/laboratorio">
            <v-icon>mdi mdi-office-building</v-icon>
            <v-list-item-title class="pl-2">Laboratório</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6 pa-1" to="/usuario">
            <v-icon>mdi-account</v-icon>
            <v-list-item-title class="pl-2">Usuário</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-12" @click="confirmarSair()">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-title class="pl-2">Sair do sistema</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import { Slide } from 'vue-burger-menu'
import vuetify from "@/plugins/vuetify";
import swal from "sweetalert2";
import Swal from "sweetalert2";

export default {
  name: "MenuSuporte",
  computed: {
  },
  vuetify,
  // components: { Slide },
  data: () => ({
    drawer: false,
    group: null,
    selectedItem: 1,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  methods: {
    confirmarSair() {
      swal.fire({
        title: 'Deseja mesmo sair?',
        text: "",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      })
    },
    async logout() {
      try {
        localStorage.removeItem('token');
        localStorage.removeItem('selectedCNPJs');
        this.$router.push('/')
      } catch (err) {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          timer: 3000,
          timerProgressBar: true,
          text: 'Falha na conexão com o servidor',
        });
      }
    },
  }

}
</script>

<style scoped>
</style>