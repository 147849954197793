<template>
  <v-container fluid>
    <!-- Title Row -->
    <v-row justify="space-around" class=" pl-16 pt-5">
      <v-col cols="12">
        <v-row>
          <v-col cols="7">
            <span class="chart-title pb-0 mb-0 mt-5" style="font-size: 7vh">Business Intelligence - Patho-BI</span>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-col cols="3" class="pl-12">
                <v-list-item v-if="this.papel === 1" class="mt-9 justify-space-around" to='/empresa'>
                  <v-icon title="Empresas">mdi mdi-domain-plus</v-icon>
                </v-list-item>
              </v-col>
              <v-col cols="3" class="pl-12">
                <v-list-item v-if="this.papel === 1" class="mt-9 justify-space-around" to='/laboratorio'>
                  <v-icon title="Laboratórios">mdi mdi-office-building-plus</v-icon>
                </v-list-item>
              </v-col>
              <v-col cols="3" class="pl-12">
                <v-list-item v-if="this.papel === 1" class="mt-9 justify-space-around" to='/usuario'>
                  <v-icon title="Usuários">mdi-account-plus</v-icon>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-img width="15vh" class="mr-5" :src="require('@/assets/images/pc-pw_1.png')"/>
          </v-col>
        </v-row>
        <v-row>
          <div class="light-blue-rectangle"></div>
        </v-row>
      </v-col>
    </v-row>

    <!-- First Row -->
    <v-row justify="space-around" class="mt-7">
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/examesAbertos">
              <v-img height="30hv" :src="require('@/assets/images/examesemaberto.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out cyan darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/tempoDeExamesPorSetor">
              <v-img height="30hv" :src="require('@/assets/images/tes.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out light-green darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/conclusao">
              <v-img height="30hv" :src="require('@/assets/images/conclusao.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out light-blue darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/numeroExamesLiberados">
              <v-img height="30hv" :src="require('@/assets/images/evolucaomensaldosexames.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out yellow darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <!-- Second Row -->
    <v-row justify="space-around">
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/examesEncerrados">
              <v-img height="30hv" :src="require('@/assets/images/examesencerrados.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/examesEncerradosClassificador">
              <v-img height="30hv" :src="require('@/assets/images/examesencerradosporclassificacao.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/tempoDeEntrega">
              <v-img height="30hv" :src="require('@/assets/images/tempodeentrega.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out pink darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" max-width="34vh">
            <router-link to="/evolucaoDoNumeroDeExamesPorPatologista">
              <v-img height="30hv" :src="require('@/assets/images/evolucaoporpatologista.png')">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h2 white--text " style="height: 10%;"></div>
                </v-expand-transition>
              </v-img>
            </router-link>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import api from "@/plugins/api";

export default {
  name: 'Welcome',

  data(){
    return {
      papel: null
    }
  },

  mounted() {
    this.findPapelDoUsuario();
  },

  methods: {
    findPapelDoUsuario(){
      api.get('api/autenticacao/isSuperUsuario').then(response => {
        this.papel = response.data[0];
      });
    }
  }
};
</script>

<style scoped>
.chart-title {
  color: #fff; /* Cor branca */
  font-style: italic; /* Itálico */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.light-blue-rectangle {
  width: 185vh;
  height: 2vh;
  background-color: #2196f3;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

</style>
