import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import {colors} from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        dark: true,
        themes: {
            light: {
                primary: '#23272b',
                background: colors.indigo.base,
                info: colors.teal.lighten1,
            },
        }
    }
});
