<template>
  <v-container fluid id="app">
    <v-app>
      <router-view/>
    </v-app>
  </v-container>
</template>

<script>


import vuetify from "@/plugins/vuetify";

export default {
  name: 'app',
  vuetify,
}
</script>

<style scoped>
#app {
  background-color: transparent;
}
</style>