<template>
  <v-container>
    <v-row class="mb-10">
      <v-row justify="space-around" class=" pl-16 pt-5">
        <v-col cols="12">
          <v-row>
            <MenuSuporte class="mt-6"></MenuSuporte>
            <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Configurações - {{ this.laboratorio.nome }}</span>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="5">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Editar laboratório</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="nomeNovo" label="Novo nome do laboratório"/>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bg-primary" block @click="editLaboratorio">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Mudar empresa</h3>
            </v-card-title>
            <v-card-text>
              <v-select v-model="empresaSelecionada" :items="empresas" item-text="nome" label="Empresa"></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bg-primary" block @click="mudarEmpresaLaboratorio">Mudar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
        <slot>
          <v-card color="#121212" class="text-center centered justify-center mt-4" elevation="8" >
            <v-card-title>
              <h4 class="display-1">Desativar laboratório</h4>
            </v-card-title>
            <v-card-actions>
              <v-btn class="error" block @click="desativarLaboratorio">Desativar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
      </v-col>
      <v-col cols="7">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Informações do laboratório</h3>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-card-title>
                  <h4 class="display-1">Empresa: {{ empresa.nome }}</h4>
                </v-card-title>
                <v-card-title>
                  <h4 class="display-1">CNPJ: {{ laboratorio.cnpj }}</h4>
                </v-card-title>
                <v-card-title>
                  <h4 class="display-1">Nome: {{ laboratorio.nome }}</h4>
                </v-card-title>
              </v-card>
            </v-card-text>
          </v-card>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import api from "@/plugins/api";
import Swal from "sweetalert2";
import MenuSuporte from "@/components/MenuSuporte.vue";

export default {
  components: {MenuSuporte},
  data(){
    return {
      laboratorio: '',
      laboratorios: [],
      filtroLaboratorio: '',
      nomeNovo: '',
      empresa: '',
      empresaSelecionada: '',
      empresas: [],
    }
  },

  mounted() {
    const url = window.location.href
    this.pegarLaboratorioAPartirDaURL(url)
    this.findEmpresas()
  },

  methods: {
    pegarLaboratorioAPartirDaURL(url){
      const partes = url.split("/");
      const laboratorioId = partes[partes.length - 1];

      api.post('api/configuracoes/laboratorioPeloId', {laboratorioId: laboratorioId}).then(response => {
        this.laboratorio = response.data[0];
      });

      api.post('api/configuracoes/empresaPorLaboratorio', {laboratorioId: laboratorioId}).then(response => {
        this.empresa = response.data[0];
      });
    },

    findLaboratorios(){
      const url = window.location.href
      const partes = url.split("/");
      const laboratorioId = partes[partes.length - 1];
      api.post('api/configuracoes/empresaPorLaboratorio', {nome: this.filtroLaboratorio, laboratorioId: laboratorioId}).then(response => {
        this.laboratorios = response.data[0];
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    findEmpresas(){
      api.post('api/configuracoes/empresaslist', {nome: ''}).then(response => {
        this.empresas = response.data;
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    async editLaboratorio(){
      const url = window.location.href
      const partes = url.split("/");
      const laboratorioId = partes[partes.length - 1];
      try {
        if(this.nomeNovo.length < 2){
          return await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Informe o nome da laboratorio.',
          });
        }
        await api.post('/api/configuracoes/editLaboratorio', {nome: this.nomeNovo, laboratorioId: laboratorioId});
        location.reload();
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    },

    async mudarEmpresaLaboratorio(){
      const url = window.location.href
      const partes = url.split("/");
      const laboratorioId = partes[partes.length - 1];
      try {
        await api.post('/api/configuracoes/mudarEmpresaLaboratorio', {empresa: this.empresaSelecionada, laboratorioId: laboratorioId});
        location.reload();
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    },

    async desativarLaboratorio(){
      try {
        const url = window.location.href
        const partes = url.split("/");
        const laboratorioId = partes[partes.length - 1];
        await api.post('/api/configuracoes/desativarLaboratorio', {laboratorioId: laboratorioId});
        this.$router.push('/laboratorio');
        await Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Laboratorio desativado com sucesso.',
        });
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }

    },

    hubLaboratorio(id) {
      if(id) {
        this.$router.push({ path: `/laboratorioHub/${id}` });
      }
    },
  }


}

</script>

<style scoped>

</style>