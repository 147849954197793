<template>
  <v-container>
    <v-row class="mb-10">
      <v-row justify="space-around" class=" pl-16 pt-5">
        <v-col cols="12">
          <v-row>
            <MenuSuporte class="mt-6"></MenuSuporte>
            <span class="chart-title pl-7 pb-0 mb-0" style="font-size: 7vh">Configurações - {{ this.empresa.nome }}</span>
          </v-row>
          <v-row>
            <div class="light-blue-rectangle mt-3"></div>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="4">
        <slot>
          <v-card color="#121212" class="text-center centered justify-center" elevation="8" >
            <v-card-title>
              <h3 class="display-2">Editar empresa</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="nomeNovo" label="Novo nome da empresa"/>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bg-primary" block @click="editEmpresa">Editar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
        <slot>
          <v-card color="#121212" class="text-center centered justify-center mt-4" elevation="8" >
            <v-card-title>
              <h4 class="display-1">Desativar empresa</h4>
            </v-card-title>
            <v-card-actions>
              <v-btn class="error" block @click="desativarEmpresa">Desativar</v-btn>
            </v-card-actions>
          </v-card>
        </slot>
      </v-col>
      <v-col cols="8">
        <slot>
          <v-card color="#121212" class="text-center" elevation="8">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item-title class="mt-2">Laboratórios ligados</v-list-item-title>
                <v-divider class="mt-4"></v-divider>
                <v-row>
                  <v-col cols="3">
                  </v-col>
                  <v-col cols="8">
                    <v-text-field persistent-hint hint="Filtrar por nome" class="mt-6" style="width: 50vh;" v-model="filtroLaboratorio" label="Nome do laboratório" filled rounded dense append-icon="mdi mdi-magnify"></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-1"></v-divider>
                <v-list-item @click="hubLaboratorio(lab.id)" v-for="(lab, j) in laboratorios" :key="j">
                  <v-list-item-icon>
                    <v-icon>mdi mdi-office-building</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="lab.nome"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title v-text="lab.cnpj"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import api from "@/plugins/api";
import Swal from "sweetalert2";
import MenuSuporte from "@/components/MenuSuporte.vue";

export default {
  components: {MenuSuporte},
  data(){
    return {
      empresa: '',
      laboratorios: [],
      filtroLaboratorio: '',
      nomeNovo: '',
      nomeEmpresa: '',
    }
  },

  watch: {
    'filtroLaboratorio': 'findLaboratorios',
  },

  mounted() {
    const url = window.location.href
    this.pegarEmpresaAPartirDaURL(url)
  },

  methods: {
    pegarEmpresaAPartirDaURL(url){
      const partes = url.split("/");
      const empresaId = partes[partes.length - 1];

      api.post('api/configuracoes/empresaPeloId', {empresaId: empresaId}).then(response => {
        this.empresa = response.data[0];
      });

      api.post('api/configuracoes/laboratoriosPorEmpresa', {nome: this.filtroLaboratorio, empresaId: empresaId}).then(response => {
        this.laboratorios = response.data[0];
      });
    },

    findLaboratorios(){
      const url = window.location.href
      const partes = url.split("/");
      const empresaId = partes[partes.length - 1];
      api.post('api/configuracoes/laboratoriosPorEmpresa', {nome: this.filtroLaboratorio, empresaId: empresaId}).then(response => {
        this.laboratorios = response.data[0];
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      })
    },

    async editEmpresa(){
      const url = window.location.href
      const partes = url.split("/");
      const empresaId = partes[partes.length - 1];
      try {
        if(this.nomeNovo.length < 2){
          return await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Informe o nome da empresa.',
          });
        }
        await api.post('/api/configuracoes/editEmpresa', {nome: this.nomeNovo, empresaId: empresaId});
        location.reload();
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }
    },

    async desativarEmpresa(){
      try {
        const url = window.location.href
        const partes = url.split("/");
        const empresaId = partes[partes.length - 1];
        await api.post('/api/configuracoes/desativarEmpresa', {empresaId: empresaId});
        this.$router.push('/empresa');
        await Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Empresa desativada com sucesso.',
        });
      } catch (err) {
        if (err.response) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: err.response.data,
          });
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Oops...',
            timer: 3000,
            timerProgressBar: true,
            text: 'Falha na conexão com o servidor',
          });
        }
      }

    },

    hubLaboratorio(id) {
      if(id) {
        this.$router.push({ path: `/laboratorioHub/${id}` });
      }
    },
  }


}

</script>

<style scoped>

</style>